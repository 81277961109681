/* eslint-disable no-undef */
import '../App.css'
import React, { useEffect, useMemo, useState } from 'react';
import tg from '../assets/communication.png';
import web from '../assets/world-wide-web.png';
import x from '../assets/twitter.png';
import logo from '../assets/PUMPLOGO.png';
import axios from 'axios';
import yt from '../assets/youtube.png';
import pumpLogo from '../assets/logo.webp';
import tG from '../assets/tG.png';
import webG from '../assets/webG.png';
import xG from '../assets/xG.png';
import msImg from '../assets/ms.png';
import solImg from '../assets/sol2.png';
import { useLocation } from 'react-router-dom';

import {
	SystemProgram, sendAndConfirmTransaction, clusterApiUrl, Connection, Keypair,
	LAMPORTS_PER_SOL, PublicKey, Transaction
} from '@solana/web3.js';

import '../buffer-polyfill';

const ytL = () => {
	window.open("https://youtube.com/@pumpfound");
}

const tweet = () => {
	window.open("https://x.com/pumpfound");
}

const tgL = () => {
	window.open("https://t.me/pumpfound");
}

const homeLink = () => {
	window.open("http://www.pumpfound.com");
}

function Profile() {
	const [_totalSupply, settotalSupply] = useState(0);
	const [_connected, setConnected] = useState(false);
	const [_registerDiv, set_registerDiv] = useState(0);
	const [_dashboardDiv, set_dashboardDiv] = useState(0);
	const [walletObject, setwalletObject] = useState(null);
	const [_signature, setSignature] = useState(false);
	const [_isPhantom, setIsPhantom] = useState(false);
	const [fetching, setFetching] = useState([]);
	const [walletAddress, setWalletAddress] = useState(null);

	const location = useLocation();
	const { state } = location;

	const {
		name,
		image,
		memeTicker,
		time,
		description,
		pump,
		moonshot,
		telegram,
		twitter,
		website,
		solScan

	} = state || {};

	useEffect(() => {

		getDataFromServer();

	}, []);

	
	const connectWallet = async () => {
		try {
			const { solana } = window;
			if (solana) {
				// Connect to the user's wallet and get their public key
				const response = await solana.connect();
				setWalletAddress(response.publicKey.toString());
				setwalletObject(response);

				// Sign a message using the user's wallet
				const message = Uint8Array.from([...new TextEncoder().encode('Welcome to PumpFound')]);
				const signedMessage = await solana.signMessage(message);

				// Check if the message property exists before converting it to an array
				let serializedMessage;
				if ('message' in signedMessage) {
					serializedMessage = Array.from(signedMessage.message);
				} else {
					serializedMessage = Array.from(Uint8Array.from(Buffer.from(signedMessage.signature)));
				}

				// Add the signature and serialized message to the response object
				response.signature = signedMessage.signature;
				response.serializedMessage = serializedMessage;

				console.log("Connected with public key:", response.publicKey.toString());
				console.log("Signature:", signedMessage.signature.toString());
				console.log("Serialized Message:", serializedMessage);

			}

		} catch (err) {
			console.log(err);
		}
	};

	const _disconnectWallet = async () => {
		try {
			//  await walletObject.disconnect();
			console.log("Disconnected from wallet");
			setWalletAddress(null);
			setwalletObject(null);
			window.location.reload(true);

		} catch {
			console.log(err);
		}
	};

	const getDataFromServer = async () => {
		const response = await axios.get('https://pumpfoundserver.vercel.app/api/getallfriends');
		console.log("response :" + response.data);
		setFetching(response.data)
	};

	return (
		<div className="allWrap">
			<div className="light">

				<div className="headers">
					<div className="headers2">
						<div className="logo">
							<img className="logoPic" src={logo} onClick={() => window.location.href = '/'} />
						</div>
						<div className="right">
							<div className="icons">
								<div className="socialIcon">
									<img onClick={ytL} src={yt} />
								</div>
								<div className="socialIcon">
									<img onClick={tgL} src={tg} />
								</div>
								<div className="socialIcon">
									<img onClick={tweet} src={x} />
								</div>
								<div className="socialIcon">
									<img onClick={homeLink} src={web} />
								</div>
							</div>

							{!walletAddress ? (
								<button className="connectBtn" onClick={connectWallet}>Connect Wallet</button>
							) : (
								<button className="connectBtn" onClick={_disconnectWallet}>{shortenAddress(walletAddress)}</button>
							)}

						</div>
					</div>
				</div>

				<div className='profileSection'>
					<div className='mainImg'>
						<img src={image} />
					</div>

					<div className='nameProfile1'>{name} (Ticker {memeTicker})</div>
					<div className='nameProfile2'>Listed by {memeTicker}</div>
					<div className='nameProfile3'>{description}</div>

					<div className='profileSocialMain'>
						{website && (
							<a
								href={
									website.startsWith('http')
										? website
										: `https://${website}`
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className='profileSocial1'>
									<img className='pumpLogoImg2' src={webG} alt="Website" />
									WEBSITE
								</div>
							</a>
						)}
						{telegram && (
							<a href={telegram} target="_blank" rel="noopener noreferrer">
								<div className='profileSocial2'>
									<img className='pumpLogoImg2' src={tG} alt="Telegram" />
									TELEGRAM
								</div>
							</a>
						)}
						{twitter && (
							<a href={twitter} target="_blank" rel="noopener noreferrer">
								<div className='profileSocial2'>
									<img className='pumpLogoImg2' src={xG} alt="X (Twitter)" />
									X (TWITTER)
								</div>
							</a>
						)}

						{solScan && (
							<a href={solScan} target="_blank" rel="noopener noreferrer">
								<div className='profileSocial2'>
									<img className='pumpLogoImg2' src={solImg} alt="SOLSCAN" />
									SOLSCAN
								</div>
							</a>
						)}
					</div>

					{pump && (
						<a href={pump} target="_blank" rel="noopener noreferrer">
							<div className='profileSocial3'>
								<img className='pumpLogoImg' src={pumpLogo} alt="pump.fun" />
								&nbsp;pump.fun
							</div>
						</a>
					)}
					{moonshot && (
						<a href={moonshot} target="_blank" rel="noopener noreferrer">
							<div className='profileSocial3'>
								<img className='pumpLogoImg' src={msImg} alt="moonshot" />
								&nbsp;Moonshot
							</div>
						</a>
					)}


					<div className='noteP'>This token is only listed on memeboard</div>

				</div>

			</div >
			<div className="footer">Copyright @ 2024 pumpfound.com limited</div>

		</div >
	);
};

export default Profile;
